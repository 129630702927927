<template>
    <p v-if="show" class="detail-description">
        {{ content }}
    </p>
</template>

<script>
export default {
    name: 'DetailDescription',
    props: {
        name: String,
    },
    computed: {
        show() {
            return this.isInSeoul || this.isMajor || this.isProfession || this.isProfessionalSchool
        },
        isInSeoul() {
            return this.name === '인서울'
        },
        isMajor() {
            return this.name === '명문대'
        },
        isProfession() {
            return this.name === '전문직'
        },
        isProfessionalSchool() {
            return this.name === '전문대학원'
        },
        content() {
            if (!this.show) return ''

            if (this.isInSeoul) return this.$translate('INSEOUL_UNIVERSITIES')

            if (this.isMajor) return this.$translate('MAJOR_UNIVERSITIES')

            if (this.isProfession) return this.$translate('PROFESSIONS')

            if (this.isProfessionalSchool) return this.$translate('PROFESSIONAL_SCHOOLS')

            return ''
        },
    },
}
</script>

<style lang="scss" scoped>
.detail-description {
    background-color: $grey-01;
    border: solid 1px $grey-03;
    border-radius: 12px;
    padding: 8px 12px;

    font-size: 12px;
    color: $grey-07;
    font-weight: 500;
    line-height: normal;

    @include f-medium;
}
</style>
