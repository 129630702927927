<template>
    <div>
        <h2 class="section-title">뱃지 획득 방법</h2>
        <ul v-if="!shouldHardCoded" class="verification-method" v-html="verificationMethod" />
        <ul v-else-if="isIncome" class="verification-method">
            <li class="flex-row flex-between m-b-10">
                <div class="c-text flex-row">
                    <span class="f-medium c-black m-r-12">근로소득자:</span>
                    <p>원천징수영수증<br />혹은 지급명세서</p>
                </div>
                <div class="text-underline f-medium c-primary" @click="onClickDetail('worker')">발급방법보기</div>
            </li>
            <li class="flex-row flex-between">
                <div class="c-text flex-row">
                    <span class="f-medium c-black m-r-12">사업소득자:</span>
                    <p>소득금액증명원</p>
                </div>
                <div class="text-underline f-medium c-primary" @click="onClickDetail('business')">발급방법보기</div>
            </li>
        </ul>
        <ul v-else-if="isRealEstate" class="verification-method real-estate">
            <li class="li">
                <span class="required m-r-12">필수</span>
                <p>소유권을 증명할 수 있는 부동산 등기서류 혹은 매매계약서</p>
            </li>
        </ul>
        <ul v-else-if="isAsset" class="verification-method asset">
            <li class="li">
                <span class="optional m-r-12 m-t-2">택1</span>
                <div>
                    <p class="m-b-8">부동산의 경우</p>
                    <p class="f-12">
                        <span class="f-bold">필수1.</span>소유권을 증명할 수 있는 부동산 등기서류 혹은 매매계약서
                    </p>
                    <p class="f-12"><span class="f-bold">필수2.</span>네이버 부동산 실거래가 캡쳐 화면</p>
                </div>
            </li>
            <li class="li">
                <span class="required m-r-12 m-t-2">택2</span>
                <div>
                    <p class="m-b-8">현금의 경우</p>
                    <p class="f-12">
                        시중 은행에서 발행한 잔고/잔액 증명서<br />
                        (*본인 성함이 나온 최근 일자 서류)
                    </p>
                </div>
            </li>
            <li class="li"><span class="required m-r-12 m-t-2">택3</span>그 외 자산을 인증할 수 있는 서류</li>
        </ul>
        <ul v-else-if="isCar" class="verification-method car">
            <li class="li">
                <span class="f-medium flex-wrap m-r-12">본인차량:</span>
                <p>본인 성함이 기재된 보험계약서 혹은 자동차등록증</p>
            </li>
            <li class="li">
                <span class="f-medium flex-wrap m-r-12">리스차량:</span>
                <p>본인 성함이 기재된 보험계약서 혹은 리스계약서</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'VerificationMethod',
    props: {
        badge: {
            type: Object,
            required: true,
        },
    },
    computed: {
        // 사실 이러면 안됨;; 추후 리팩토링 ㄱㄱ..
        shouldHardCoded() {
            return this.isRealEstate || this.isAsset || this.isIncome || this.isCar
        },
        hasOptionalMethod() {
            return (
                [
                    '학교인증',
                    '직장인증',
                    '신분증인증',
                    '명문대',
                    '인서울',
                    '전문대학원',
                    '전문직',
                    '대기업',
                    '공공기관/공기업',
                ].indexOf(this.badge.name) > -1
            )
        },
        isRealEstate() {
            return this.badge.name === '자가'
        },
        isAsset() {
            return ['고액자산', '초고액자산'].indexOf(this.badge.name) > -1
        },
        isIncome() {
            return this.badge.name.includes('소득')
        },
        isCar() {
            return ['자차', '수입차'].indexOf(this.badge.name) > -1
        },
        verificationMethod() {
            const vm = this.badge.verification_method

            if (!vm) return ''

            if (vm.includes('|')) {
                const methods = vm.split('|')
                let html = ''

                methods.forEach((m, idx) => {
                    if (this.hasOptionalMethod) {
                        html += `
                            <li class="${idx < methods.length - 1 ? 'm-b-12' : ''}">
                                <span class="m-r-12 optional">택${idx + 1}</span>${m}
                            </li>
                        `
                    } else {
                        html += `
                            <li class="${idx < methods.length - 1 ? 'm-b-12' : ''}">
                                ${m}
                            </li>
                        `
                    }
                })

                return html
            }

            return this.badge.verification_method
        },
    },
    methods: {
        onClickDetail(type) {
            let url = ''

            if (type === 'worker') {
                url =
                    'https://help.jobis.co/hc/ko/articles/900004808503-%EC%A7%80%EA%B8%89%EB%AA%85%EC%84%B8%EC%84%9C-%EA%B7%BC%EB%A1%9C%EC%86%8C%EB%93%9D-%EC%9B%90%EC%B2%9C%EC%A7%95%EC%88%98-%EC%98%81%EC%88%98%EC%A6%9D-%EB%B0%9C%EA%B8%89%EB%B0%A9%EB%B2%95'
            } else if (type === 'business') {
                url =
                    'https://help.jobis.co/hc/ko/articles/115004467727-%EC%86%8C%EB%93%9D%EA%B8%88%EC%95%A1%EC%A6%9D%EB%AA%85-%EB%B0%9C%EA%B8%89%EB%B0%A9%EB%B2%95'
            }

            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: url,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.section-title {
    font-size: 16px;
    color: black;
    font-weight: 500;
    margin-bottom: 16px;

    @include f-medium;
}

.verification-method {
    background-color: $grey-01;
    border-radius: 12px;
    padding: 16px;
    color: black;
    font-size: 14px;
    margin-bottom: 50px;
    line-height: normal;

    ::v-deep .optional,
    .required {
        background-color: $grey-07;
        border-radius: 6px;
        padding: 0 6px;
        font-size: 12px;
        color: white;

        @include f-medium;
    }

    // ::v-deep span:not(:last-child) {
    //     margin-bottom: 12px;
    // }

    &.asset,
    &.real-estate,
    &.car {
        .li {
            display: flex;
            align-items: flex-start;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .optional,
            .required {
                white-space: nowrap;
            }
        }
    }
}
</style>
