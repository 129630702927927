<template>
    <div class="verification-badges-create">
        <StackRouterHeaderBar :title="headerTitle" :showTitle="showTitle" />

        <main class="main" @scroll="onScroll">
            <section class="section top">
                <img class="image m-b-24" :src="imagePath" />
                <p class="m-b-8 name">{{ badge.name }}</p>
                <p class="description" v-html="badge.description" />

                <DetailDescription class="m-t-24 m-l-18 m-r-18" :name="badge.name" />
                <div v-if="showBadgeExample" class="badge-example flex-row">
                    <img class="example-image" :src="require(`@/assets/images/verification-badge/badge_example.png`)" />
                    <div class="example-text flex">
                        <p class="example-title">매력 뱃지에는 인증 사진이 공개돼요!</p>
                        <p class="example-content" v-html="'(프로필에서 확인할 수 있어요)'" />
                    </div>
                </div>
            </section>

            <section class="section">
                <VerificationMethod :badge="badge" />

                <div v-if="verificationTypes.length > 1" class="tabs m-b-32">
                    <div
                        v-for="type in verificationTypes"
                        :key="type"
                        class="tab"
                        :class="{ selected: selectedType === type }"
                        @click="onClickTab(type)"
                    >
                        {{ typeToString(type) }}
                    </div>
                </div>

                <template v-if="selectedType === 'photo'">
                    <div v-if="badge.example_photo_url">
                        <h2 class="section-title">인증 예시</h2>
                        <img class="example-image" :src="badge.example_photo_url" />

                        <h2 class="section-title m-t-32">증빙 서류(사진)를 첨부해주세요</h2>
                        <ul class="attach-photos">
                            <li
                                v-for="(photo, idx) in photos"
                                :key="photo.url"
                                class="attach-photo"
                                @click="onClickPhoto(idx)"
                            >
                                <img v-if="photo.url" :src="photo.url" />
                                <i v-else class="material-icons">add</i>
                            </li>
                        </ul>
                    </div>

                    <div v-else-if="badge.verification_type !== 'auto'">
                        <h2 class="section-title">인증 사진 첨부</h2>
                        <div class="attach-photo" @click="onClickPhoto(0)">
                            <img v-if="photos[0].url" :src="photos[0].url" />
                            <i v-else class="material-icons">add_a_photo</i>
                        </div>
                    </div>
                </template>

                <template v-else-if="selectedType === 'email'">
                    <h2 class="section-title">이메일로 인증</h2>
                    <p v-if="isSchoolType" class="m-b-16 email-desc">
                        * 학교 공식 이메일 주소를 입력해주세요.<br />
                        (@oo.ac.kr @oo.edu 등 교육기관 이메일)
                    </p>
                    <p v-else-if="isCompanyType" class="m-b-16 email-desc">* 직장 이메일 주소를 입력해주세요.</p>
                    <input class="email-input" type="text" placeholder="이메일을 입력해주세요" v-model="email" />
                </template>
            </section>
        </main>
        <!--this.realNameVerify ? submitRealNameVerify : onClickSubmit-->
        <footer class="footer">
            <button class="btn btn-primary" @click="onClickSubmit" :disabled="disabledButton">
                {{ buttonText }}
            </button>
        </footer>

        <input
            ref="imageUploader"
            type="file"
            class="image-input display-none"
            @change="onChangeImage($event)"
            accept="image/*"
        />
    </div>
</template>

<script>
import StackRouterHeaderBar from '@/components/app/StackRouterHeaderBar.vue'
import verificationBadgeService from '@/services/verification-badge'
import DetailDescription from './components/DetailDescription'
import VerificationMethod from './components/VerificationMethod'

export default {
    name: 'VerificationBadgesCreatePage',
    components: { StackRouterHeaderBar, DetailDescription, VerificationMethod },
    props: {
        badge: Object,
    },
    data: () => ({
        showTitle: false,
        photos: null,
        selectedIndex: null,
        selectedType: 'photo',
        email: '',
    }),
    watch: {
        '$store.getters.niceData': {
            handler(newVal) {
                const checkGender = niceGender => {
                    // nice => female '0' male '1' // vb => male 0 female 1
                    const vbGender = this.$store.getters.me.gender
                    return !(Number(niceGender) === vbGender)
                }

                if (checkGender(newVal.gender)) this.finishVerification(newVal)
                else {
                    this.$modal.basic({
                        title: '뱃지 획득에 실패했어요',
                        body: '가입시 성별과 달라요. 뱃지를 획득하려면 카카오톡 플러스 친구에 문의 주세요!',
                        buttons: [
                            {
                                label: '확인',
                                class: 'btn-default',
                            },
                        ],
                    })
                }
            },
            deep: true,
        },
    },
    computed: {
        realNameVerify() {
            return this.badge.name === '실명인증'
        },
        disabledButton() {
            // 본인인증 뱃지 획득
            if (this.realNameVerify) return false

            const isAutoType = this.verificationTypes.includes('auto')

            // 기준을 충족하면 자동으로 지급되는 뱃지
            if (isAutoType) return true

            // 사진을 제출해야하는데 사진 업로드를 안한 경우
            if (this.selectedType === 'photo')
                return this.verificationTypes.includes('photo') && this.photos.every(p => !p.url)
            // 이메일 입력하지 않은 경우
            else if (this.selectedType === 'email') return this.verificationTypes.includes('email') && !this.email

            return true
        },
        imagePath() {
            if (!this.badge.image_name) {
                return ''
            }

            return require(`@/assets/images/verification-badge/active/${this.badge.image_name}.svg`)
        },
        headerTitle() {
            return `${this.$translate(this.badge.category.toUpperCase())} 뱃지 획득`
        },
        verificationTypes() {
            return this.badge.verification_type.split(',')
        },
        buttonText() {
            if (this.selectedType === 'email') return '인증 메일 발송'

            return '뱃지 획득'
        },
        isSchoolType() {
            return ['학교인증', '명문대', '인서울', '전문대학원'].indexOf(this.badge.name) > -1
        },
        isCompanyType() {
            return ['직장인증', '전문직', '대기업', '공공기관/공기업'].indexOf(this.badge.name) > -1
        },
        showBadgeExample() {
            return (this.badge || {}).category === 'attraction'
        },
    },
    methods: {
        onScroll(event) {
            if (event.target.scrollTop > 50) {
                this.showTitle = true
            } else {
                this.showTitle = false
            }
        },
        onClickPhoto(index) {
            this.selectedIndex = index
            this.$refs.imageUploader.click()
        },
        onChangeImage(event) {
            if (!event || event.target.files.length === 0) return

            const selectedPhoto = this.photos[this.selectedIndex]
            selectedPhoto.name = event.target.files[0].name
            const file = event.target.files[0]
            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                        allowFreeAspectRatio: true,
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    selectedPhoto.url = URL.createObjectURL(croppedFile)
                    selectedPhoto.blob = croppedFile
                    this.selectedIndex = null
                })
        },
        async submitRealNameVerify() {
            this.$nativeBridge.postMessage({ action: 'verification' })
        },
        async finishVerification(niceData) {
            const responseId = niceData.response_id
            const payload = this.preparedPayload()
            payload.append('response_id', responseId)

            try {
                await verificationBadgeService.create(this.$store.getters.me.id, payload)
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Complete_GainBadge',
                    },
                })

                this.$toast.success('축하드려요!🎉 실명이 인증되었어요!')
                this.$hackleClient.onReady(() => {
                    const hackleUser = this.$store.getters.hackleUser
                    this.$hackleClient.track('Complete_GainBadge', hackleUser)
                })
                await this.$store.dispatch('loadMyVerificationBadges')
            } catch (e) {
                this.$toast.error(e.data.msg)
            }

            this.$stackRouter.pop()
        },
        async onClickSubmit() {
            switch (this.badge.id) {
                case 19:
                    // 실명인증
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'NameVerifPage_Click_GainVerif',
                        },
                    })
                    break
                case 18:
                    // 신분증인증
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'IDVerifPage_Click_GainVerif',
                        },
                    })
                    break
                case 17:
                    // 직장인증
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'CompanyVerifPage_Click_GainVerif',
                        },
                    })
                    break
                case 16:
                    // 직업인증
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'JobVerifPage_Click_GainVerif',
                        },
                    })
                    break
                case 15:
                    // 학교인증
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'SchoolVerifPage_Click_GainVerif',
                        },
                    })
                    break
            }

            if (this.realNameVerify) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'VerificationBadgePage_Click_GainBadge',
                    },
                })
                await this.submitRealNameVerify()
                return
            }
            try {
                if (this.selectedType === 'email' && !this.checkEmailValidation(this.email)) {
                    this.$alert(
                        `${
                            this.isSchoolType ? '학교' : '직장'
                        } 이메일 주소가 아닙니다. 오류가 의심되는 경우 카카오톡 플러스친구 '바닐라브릿지'에 문의해주세요.`,
                    )

                    return
                }

                this.$loading(true)
                const payload = this.preparedPayload()

                this.$loading(true)
                await verificationBadgeService.create(this.$store.getters.me.id, payload)
                this.$loading(false)

                this.$store.dispatch('loadMyVerificationBadges')
                this.$stackRouter.pop()
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        preparedPayload() {
            const payload = new FormData()

            payload.append('verification_badge_id', this.badge.id)
            payload.append('verification_type', this.verificationTypes.includes('auto') ? 'auto' : this.selectedType)

            this.photos.forEach((photo, idx) => {
                if (photo.blob) {
                    payload.append(`photo${idx + 1}`, photo.blob, photo.name)
                }
            })

            if (this.selectedType === 'email') {
                payload.append('email_address', this.email)

                if (this.isSchoolType) {
                    payload.append('email_type', 'school')
                } else if (this.isCompanyType) {
                    payload.append('email_type', 'company')
                }
            }

            return payload
        },
        onClickTab(type) {
            this.selectedType = type
        },
        typeToString(type) {
            if (type === 'photo') return '증빙 서류(사진)'

            if (type === 'email') return '이메일'
        },
        checkEmailValidation(address) {
            if (this.isSchoolType) return !!address.match(/ac.kr|.edu|ewhaian.net/gi)
            if (this.isCompanyType) return !address.match(/naver.com|daum.com|gmail.com|nate.com/gi)
        },
    },
    created() {
        switch (this.badge.id) {
            case 19:
                // 실명인증
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Enter_NameVerifPage',
                    },
                })
                break
            case 18:
                // 신분증인증
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Enter_IDVerifPage',
                    },
                })
                break
            case 17:
                // 직장인증
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Enter_CompanyVerifPage',
                    },
                })
                break
            case 16:
                // 직업인증
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Enter_JobVerifPage',
                    },
                })
                break
            case 15:
                // 학교인증
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Enter_SchoolVerifPage',
                    },
                })
                break
        }
        this.photos = Array(3)
            .fill({})
            .map(p => {
                return { blob: null, name: null, url: null }
            })
    },
}
</script>

<style lang="scss" scoped>
.verification-badges-create {
    $footer-height: 80px;

    &::-webkit-scrollbar {
        display: none;
    }

    .main {
        width: 100vw;
        height: calc(100vh - #{$stack-router-header-height} - #{$footer-height});
        overflow-x: hidden;
        overflow-y: auto;
    }

    .tabs {
        .tab.selected {
            color: black;
            border-bottom-color: black;
        }
    }

    .section {
        display: flex;
        flex-direction: column;
        padding: 36px 16px 40px 16px;

        &-title {
            font-size: 16px;
            color: black;
            font-weight: 500;
            margin-bottom: 16px;

            @include f-medium;
        }

        .badge-example {
            background-color: rgba(#8a74ff, 0.08);
            border-radius: 14px;
            padding: 8px 16px;
            margin-top: 28px;
            // margin-bottom: 44px;
            align-items: center;
            width: 100%;

            .example-image {
                width: 32px;
                margin-right: 20px;
            }

            .example-text {
                padding: 6px 2px 6px 0px;
                .example-title {
                    font-size: 14.5px;
                    color: black;
                    margin-bottom: 2px;
                    @include f-regular;
                }

                .example-content {
                    font-size: 14px;
                    color: $grey-06;
                }
            }
        }
    }

    .top {
        align-items: center;
        border-bottom: solid 1px $grey-02;

        .image {
            width: 120px;
        }

        .name {
            font-size: 18px;
            color: black;

            @include f-medium;
        }

        .description {
            font-size: 14px;
            color: $grey-08;
            text-align: center;
            line-height: normal;
        }
    }

    .attach-photo {
        border-radius: 12px;
        border: solid 1px $grey-03;
        width: calc(100vw - 32px);
        height: calc(100vw - 32px);
        overflow: hidden;

        .material-icons {
            font-size: 32px;
            color: $grey-06;
        }

        img {
            width: 100%;
            height: 100%;
        }

        @include center;
    }

    .attach-photos {
        $gap: 11px;
        $photo-size: calc((100vw - #{$gap * 2} - 32px) / 3);
        display: grid;
        grid-template-columns: repeat(3, $photo-size);
        column-gap: 11px;

        .attach-photo {
            width: $photo-size;
            height: $photo-size;
        }

        .material-icons {
            font-size: 24px;
        }
    }

    .example-image {
        width: 100%;
    }

    .email-desc {
        font-size: 12px;
        color: $purple-primary;

        @include f-medium;
    }

    .email-input {
        height: 48px;
        padding: 14px 16px;
        font-size: 15px;
        -webkit-appearance: none;
    }

    .footer {
        position: fixed;
        bottom: 0;
        height: $footer-height;
        background-color: white;
        padding: 16px;
        border-top: solid 1px $grey-02;

        &,
        .btn {
            width: 100%;
        }

        .btn {
            height: 48px;
        }
    }
}
</style>
